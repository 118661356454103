import io from 'socket.io-client';
import { merge } from 'lodash';
import store from '@/store';
import { bus, lightningIntegration } from '@/utility';
import types from '@/store/mutationTypes';

const updateBalanceManually = () => {
  if (store.getters.isLightningIntegration && store.getters.manualBalanceUpdate) {
    lightningIntegration.updateBalance();
    lightningIntegration.clearManualBalanceUpdate();
    lightningIntegration.startManualBalanceUpdate();
  }
};

const setListener = (socket) => {
  socket.on('message', (message) => {
    const data = JSON.parse(message);
    const round = merge({}, data.round, {
      event: merge({}, data.event),
    });
    // eslint-disable-next-line
    const nextRounds = data.nextRounds;

    if (data.type === 'draw' && store.getters.ticket.bets.length) {
      store.commit(types.UPDATE_TICKET_BETS, store.getters.ticket);
      bus.$emit('updateBets');
    }

    if (data.type === 'countdown') {
      bus.$emit('refreshTicketList');
      updateBalanceManually();
    }

    if (data.type === 'results') {
      const { channel, isUserAuthenticated } = store.getters;
      if (isUserAuthenticated) {
        if (channel === 'web' || channel === 'mobile') {
          store.dispatch('loadPlayedTickets', { includeBets: true });
          updateBalanceManually();
        }
      }
    }

    store.commit(types.SET_ROUND, round);
    store.commit(types.SET_NEXT_ROUNDS, nextRounds);
  });
};

export default {
  init(config) {
    const socketUrl = `${process.env.VUE_APP_SOCKET_URL}:${store.getters.config.socket.port}`;
    const socket = io(socketUrl, {
      query: `wheelId=${config.wheel.id}&userId=${store.getters.user.id}`,
    });
    setListener(socket);
  },
};
