import { ConfigurationService } from '@nsftx/games-config';
import { capitalize, upperFirst } from 'lodash';
import { socket, pusher } from '@/plugins';
import isMobile from 'ismobilejs';
import { bus } from '@/utility';
import store from '../store';
import { auth, ticketApi } from '../api';

const qp = new URLSearchParams(window.location.search);
let manualUpdateBalanceTimeout;
let config;

export default {
  isActive: false,
  async init() {
    const token = qp.get('token');
    const tenantId = qp.get('tenantId');
    const deliveryPlatform = qp.get('deliveryPlatform') || qp.get('application') || 'web';
    const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'postMessage';
    const configService = new ConfigurationService({
      environment: process.env.NODE_ENV,
      requiredPaths: [],
      applicationName: deliveryPlatform === 'web' ? capitalize(deliveryPlatform) : deliveryPlatform,
      messageProtocol,
      clientAdapters: [
        {
          name: `${upperFirst(messageProtocol)}Adapter`,
          options: {
            slaveId: 'PluginRoulette',
          },
        },
      ],
      ui: tenantId,
    });
    config = await configService.getConfiguration();
    store.dispatch('initializeConfiguration', config.rawData);
    socket.init(config.rawData);
    this.isActive = true;
    const {
      applicationName,
      productName,
      productId,
      locale,
      platformName,
      platformConfig,
      wheel,
      isIntegration,
    } = config;
    store.dispatch('setPlatform', {
      channel: 'web',
      isIntegration,
      platformName,
      isMobile: isMobile().any.toString(),
      currency: wheel.wheelAccountCurrency,
      productId,
      productName,
      platformConfig,
      locale,
      applicationName,
      companyUuid: tenantId,
      companyLanguage: locale,
    });
    if (token) {
      await store.dispatch('getPlayer', { config, token, tpIntegration: true });
      store.dispatch('setBalanceVisibility', 'true');
      if (store.getters.socketProvider === 'pusher') {
        if (store.getters.isWebChannel && !store.getters.user.auth.token) return;
        pusher.init();
      }
      if (store.getters.manualBalanceUpdate) {
        this.startManualBalanceUpdate();
      }
      bus.$on('ticketUpdate', this.ticketUpdate);
    }
    store.dispatch('setLimits');
    store.dispatch('setPresets');
  },
  ticketUpdate(data) {
    ticketApi.ticketUpdate(data, false);
  },
  async updateBalance() {
    const { token } = store.getters.user.auth;
    const balanceData = await auth.getBalance(config, token);
    store.dispatch('setBalance', balanceData.balance);
  },
  startManualBalanceUpdate() {
    manualUpdateBalanceTimeout = setTimeout(() => {
      this.updateBalance();
      this.clearManualBalanceUpdate();
      this.startManualBalanceUpdate();
    }, 60000);
  },
  clearManualBalanceUpdate() {
    clearTimeout(manualUpdateBalanceTimeout);
    manualUpdateBalanceTimeout = undefined;
  },
};
