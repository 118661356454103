export default {
  auth: {},
  authStrategies: ['token', 'tempToken'],
  betslip: {
    tickets: [
      {
        id: 'single',
        active: true,
        betsCollection: 'Roulette',
        betTemplate: 'generic',
        group: {
          id: 'Roulette',
          taxesConfig: {
            payin: {
              value: false,
              hideTax: false,
            },
            payout: {
              value: false,
              hideTax: false,
            },
          },
        },
        ticketGroup: 'Roulette',
        ticketType: {
          id: 3,
          name: 'single',
        },
        setStakeStrategy: 'sevenVirtualSetStake',
        setFormatPayin: 'sevenVirtualFormataPayin',
        autoStakeSetting: true,
        winningStrategy: false,
      },
    ],
  },
  config: {
    app: '',
    api: process.env.VUE_APP_API_URL,
    gateway: {
      masterOrigins: process.env.VUE_APP_GATEWAY_ORIGINS,
      slaveId: 'Roulette',
      settings: {
        shop: {
          ticketConfig: {
            availableTicketActions: {
              closed: [
                { action: 'rebet', conditions: [] },
              ],
              open: [
                { action: 'cancel', conditions: [] },
                { action: 'printCopy', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              lost: [
                { action: 'rebet', conditions: [] },
              ],
              payedout: [
                { action: 'reprintConfirmation', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              pending: [
                { action: 'cancel', conditions: [] },
                { action: 'printCopy', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              rejected: [
                { action: 'rebet', conditions: [] },
              ],
              in_play: [
                { action: 'rebet', conditions: [] },
              ],
              won: [
                { action: 'payout', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              won_overdue: [
                { action: 'payout', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
            },
            color: '#1b8a93',
            codePrefix: '4-',
            doTicketStatusCheck: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000,
            useTicketLocalStorage: true,
            validateTicket: false,
          },
          shortcuts: {},
          icon: 'n-i-roulette',
        },
      },
      keyListeners: '*',
      keyPropagation: [
        '111',
        '112',
        '113',
        '114',
        '115',
        '187',
        '16',
        '27',
        '32',
        '9',
      ],
    },
    locale: 'en',
    wheel: {},
  },
  platform: {},
  round: {},
  updateBet: false,
  nextRounds: [],
  ticket: {
    bets: [
      /*
        betName: string,
        betSelection: array,
        displayBetName: string,
        displayName: string,
        displayValue: string,
        numberOfRounds: number,
        payment: number,
        round: number,
        payment: number,
      */
    ],
    payment: 0,
    payin: 0,
    winnings: {
      min: 0,
      max: 0,
    },
  },
  ticketStatuses: {
    positive: ['won', 'paid_out'],
    negative: ['lost', 'closed'],
    neutral: ['open', 'pending'],
  },
  lastPlayedTicket: {},
  lastPlayedTickets: {},
  limits: {},
  numberOfPlayedTickets: 0,
  allowedShortcutChars: [
    ',',
    'B',
    'C',
    'D',
    'E',
    'H',
    'L',
    'O',
    'R',
    'S',
    'X',
    'Y',
    'T',
  ],
  betShortcuts: [],
  activeMessage: {},
  ticketPayment: null,
  /* Roulette */
  totalBetStake: '',
  theme: 'dark',
  palette: 'blue',
  balance: null,
  insideBetsItems: [],
  outsideBetsItems: [],
  rouletteResults: [],
  ticketResult: [],
  inputErrorMessage: null,
  line: 'line',
  row: 'row',
  top: 'top',
  center: 'center',
  bottom: 'bottom',
  special: 'specials',
  column: 'column',
  label: 'label',
  outsideBet: 'outsideBet',
  insideBet: 'insideBet',
  redTableColor: 'red-color',
  blackTableColor: 'black-color',
  columnSpot: 'column-spot',
  outsideSpot: 'outside-spot',
  clearRowDozen: 'clear-row-dozen',
  clearRowSpecial: 'clear-row-special',
  socketProvider: 'pusher',
  showLastTicketTooltip: true,
  showClearTableTooltip: true,
  tpIntegration: false,
  tpToken: null,
  siteReferer: null,
  ticketStore: [],
  preloader: true,
  preloaderProgress: 0,
  route: null,
  defaultPresets: [],
  presets: [],
  generatorCode: null,
  showBalance: false,
  backButtonEnabled: false,
  creationType: 'default',
  moneyFormat: '0,0.00',
  defaultChip: 0,
  isRemoveButtonActive: false,
  isLightningIntegration: false,
  /* eslint-disable no-template-curly-in-string */
  translations: {
    betIdLabel: 'Bet ID',
    gameInfoLabel: 'Game info',
    gameInfoPlacingBetLabel: 'Placing a bet',
    gameInfoDescription: 'The roulette wheel has 38 slots numbered 0-36 with additional 00.The objective of the game is to predict which slot the ball will land in after the wheel is spun.\n\nRoulette players have a variety of betting options. Placing inside bets is either selecting the exact number of the pocket the ball will land on, or a small range of pockets based on their proximity on the layout. Players wishing to bet on the outside will select bets on larger positional grouping of pockets, the pocket color, or whether the winning number is odd or even. The payout odds for each type of bet are based on its probability.\n\nEach round includes a countdown to spin (approx. 1:57 minutes), spin visualization, and result publication. Betting is allowed until five seconds before the spin begins.\n\nStatistics are displayed in the top-right corner of the wheel, showing individual Game number frequencies as well as statistics per region or table betting. Red indicates hot numbers, while blue represents cold numbers. Statistics are updated daily.\n\nThe RTP for the game is in the range of 92.11% - 94.74% which represents the long-term statistical percentage of total stakes in the game that is paid out as winnings over time. The RTP value is calculated by dividing the total winnings by the total stakes from a simulation of numerous game rounds.  All prizes are paid in ${currency} currency.\n\nIn the event of an internet disconnection during gameplay, simply log back into the website and reopen the game. This will allow you to resume play from the exact point where the game was interrupted. Please note, any malfunction during gameplay voids all bets, ensuring fairness and compliance with gaming regulations.',
    gameInfoNumberOfBetsTitle: 'Number of bets.',
    gameInfoNumberOfBetsDescription: 'The minimum number of bets is 1, and the maximum number of bets is ${maxBets}.',
    gameInfoSelectChipTitle: 'Select chip value.',
    gameInfoSelectChipDesciption: 'To select the desired chip value, click a chip below the table.',
    gameInfoPlacingBetsTitle: 'Placing bets.',
    gameInfoPlacingBetsDescription: 'To place a bet, select the chip value and click either a number or a betting position on the table. Point at different positions of the table to highlight the bets. Additional clicks will add the required number of chips of the same value to the selected bet. After a bet is placed, you can point at the chips on the table to view the total value of the bet and the total payout if the bet wins.',
    gameInfoRoundStartAndWheelSpinTitle: 'Round Start and Wheel Spin.',
    gameInfoRoundStartAndWheelSpinDescription: 'When the countdown reaches zero, the game round begins automatically. The ball is launched into the roulette wheel, and the placed bets are deducted from your balance. The wheel spins until the result is determined, and no manual intervention is required.',
    gameInfoWinningNumberTitle: 'Winning number.',
    gameInfoWinningNumberDescription: 'The ball lands in a pocket and the number on the pocket is the winning number. The winning number is displayed on the roulette wheel. Your winnings are also displayed in the game panel and are added to your balance. All bets are cleared from the table.',
    gameInfoInsideBetsDescription: 'Inside bets are bets placed on specific numbers on the table layout. Inside bets include the following:',
    gameInfoBetStraightDescription: 'A straight bet is a bet placed on a single number. To make a straight bet, place a chip on a number on the table.',
    gameInfoBetSplitDescription: 'A split bet is a bet placed on 2 adjoining numbers. To make a split bet, place a chip on the line between any 2 adjoining numbers except for 0 and 2 or 00 and 2.',
    gameInfoBetStreetDescription: 'A three line (street) bet is a bet on a row of 3 numbers. To make a street bet, place a chip on the line at the start of one of the 12 rows. You can also make a three line (street) bet on 0, 1, 2 or 00, 2, 3 or 0, 00 , 2 by placing a chip on the intersecting corner of the 3 numbers.',
    gameInfoBetBasketDescription: 'Bet on five numbers – 0, 00, 1, 2 and 3;  For a basket bet, place a chip on the corner intersected by 0 and a sequence of 1, 2, 3.',
    gameInfoBetCornerDescription: 'A corner bet is a bet on 4 numbers. To make a corner bet, place a chip on the intersecting corner of 4 numbers. You can also make a corner bet on 0, 1, 2, 3 by placing a chip on the intersecting corner of 0 and the row of 1, 2, 3.',
    gameInfoBetSixlineLabel: 'Six line',
    gameInfoBetSixlineDescription: 'A six line bet is a bet on 6 numbers; 2 rows of 3 numbers, excluding 0. To make a six line bet, place a chip on the start of the line that separates the 2 rows.',
    gameInfoOutsideBetsDescription: 'Outside bets are bets placed on the areas of the table that represent groups of numbers. Outside bets include:',
    gameInfoColumnDescription: 'A column bet is a bet on 12 numbers from one of the 3 columns. To make a column bet, place a chip on one of the 3 fields marked with 2 to 1 at the end of a column.',
    gameInfoDozenDescription: ' Bets on 12 numbers in three consecutive rows. Place chips in the boxes labeled 1st12, 2nd12 or 3rd12 for desired number suite. You win when the ball lands on one of the 12 numbers covered.',
    gameInfoRedBlackLabel: 'Red/Black',
    gameInfoRedBlackDescription: 'A red or black bet is a bet on 18 numbers; either all the black numbers or all the red numbers. To make a red or black bet, place a chip on either of the unlabelled red or black areas.',
    gameInfoEvenOddLabel: 'Even/Odd',
    gameInfoEvenOddDescription: 'An even or odd bet is a bet on 18 numbers; either all of the even numbers, excluding 0  and 00, or all of the odd numbers. To make an even or odd bet, place a chip on the area labeled either EVEN or ODD.',
    gameInfoLowHighLabel: '1-18/19-36',
    gameInfoLowHighDescription: 'A 1-18 or 19-36 bet is a bet on 18 numbers; either the first 18 numbers, excluding 0 and 00, or the last 18. To make a 1-18 or 19-36 bet, place a chip on the area labeled 1 to 18 or the area labeled 19 to 36.',
    gameInfoRedSplitsLabel: 'Red splits',
    gameInfoRedSplitsDescription: 'This is a 4-chip wager on all possible split (2 adjacent number) bets wherein both numbers in the sequence are red. They include split bets on 9/12, 16/19, 18/21, and 27/30.',
    gameInfoBlackSplitsLabel: 'Black splits',
    gameInfoBlackSplitsDescription: 'This is a 7-chip wager on all possible split (2 adjacent number) bets wherein both numbers in the sequence are black. They include split bets on 8/11, 10/11, 10/13, 17/20, 26/29, 28/29 and 28/31.',
    gameInfoSnakeLabel: 'Snake bet',
    gameInfoSnakeDescription: 'This is a 12-chip wager which has its name because of the snake-like pattern it makes from all the bets placed. When placing a snake bet, you are betting on the outcome being 1, 5, 9, 12, 14, 16, 19, 23, 27, 30, 32, 34.',
    gameInfoFinalePlainLabel: 'Finale plain',
    gameInfoFinalePlainDescription: 'These are bets on one number and all additional numbers that end with that number. For example, a bet on Finale plain 7 would be bet on 7, 17, 27 and a bet on Finale Plain 2 would be bet on 2, 12, 22, 32. Here’s all the Finale Plain bet options:',
    gameInfoFinaleLabel: 'Finale',
    gameInfoGameFunctionsLabel: 'Game functions',
    gameInfoHelpButton: 'Help',
    gameInfoHelpDescription: 'Click to open game info and rules.',
    gameInfoPresetsDescription: 'Opens a popup with a list of available preset bets (special bets). Selecting a preset bet automatically closes the popup and places the corresponding chips on the table. Players can create and save custom preset bets by placing chips on desired positions and selecting “+ Add current selection” in the preset popup. The player must name the custom preset before saving it. Custom presets appear above the predefined presets and can be deleted by clicking the trash can icon next to the preset name. A maximum of 15 custom preset bets can be saved. The Preset button is also available in the Info Bar.',
    gameInfoDoubleDescription: 'Doubles the value of all active bets on the table. This option is unavailable when there are no active bets or when the maximum bet limit has been reached.',
    gameInfoUndoDescription: 'Reverse the last action on the table. This option is unavailable when no bets have been placed.',
    gameInfoRebetDescription: 'Place the same bets as in the previous round.',
    gameInfoClearAllLabel: 'Clear all',
    gameInfoClearAllDescription: 'Clicking this button removes all chips from the table. This option is hidden when no chips are placed.',
    gameInfoLastTicketsDesctiption: 'Opens a list of previously paid-in tickets. Clicking a ticket in the list shows its details.',
    gameInfoPayInDescrition: 'Confirms the bet and processes the payment.',
    invalidBetShortcutError: 'Invalid bet shortcut. Please check.',
    invalidBetPayment: 'Invalid bet payment. Minimum is ${ticketBetMinPayment}, maximum is ${ticketBetMaxPayment}.',
    invalidTicketPayment: 'Invalid ticket payment. Minimum is ${ticketMinPayment}, maximum is ${ticketMaxPayment}.',
    invalidBetCount: 'Invalid number of bets. Maximum is ${maxBets}.',
    invalidBalance: 'You don\'t have enough money in your balance.',
    roundLabel: 'Round',
    roundInProgressLabel: 'in progress',
    roundStartingInLabel: 'starting in',
    bettingDisabled: 'Betting disabled',
    betOdds: 'Odds',
    results: 'Results',
    rouletteTableTabLabel: 'Table',
    rouletteWheelTabLabel: 'Wheel',
    rouletteDoubleButtonLabel: 'Double',
    rouletteRebetButtonLabel: 'Rebet',
    rouletteUndoButtonLabel: 'Undo',
    rouletteCloseButtonLabel: 'Close',
    rouletteFutureRoundsButtonLabel: 'Future rounds',
    rouletteTotalStakeButtonLabel: 'Total stake',
    roulettePayInButtonLabel: 'Pay in',
    rouletteFirstDozenBetLabel: '1st 12',
    rouletteSecondDozenBetLabel: '2nd 12',
    rouletteThirdDozenBetLabel: '3rd 12',
    rouletteLowBetLabel: 'LOW',
    rouletteEvenBetLabel: 'EVEN',
    rouletteRedBetLabel: 'RED',
    rouletteBlackBetLabel: 'BLACK',
    rouletteOddBetLabel: 'ODD',
    rouletteHighBetLabel: 'HIGH',
    rouletteFirstColumnBetLabel: '1st col.',
    rouletteSecondColumnBetLabel: '2nd col.',
    rouletteThirdColumnBetLabel: '3rd col.',
    rouletteFirstDozenTableBetLabel: '1st 12',
    rouletteSecondDozenTableBetLabel: '2nd 12',
    rouletteThirdDozenTableBetLabel: '3rd 12',
    rouletteLowTableBetLabel: 'LOW',
    rouletteEvenTableBetLabel: 'EVEN',
    rouletteRedTableBetLabel: 'RED',
    rouletteBlackTableBetLabel: 'BLACK',
    rouletteOddTableBetLabel: 'ODD',
    rouletteHighTableBetLabel: 'HIGH',
    rouletteFirstColumnTableBetLabel: '1st col.',
    rouletteSecondColumnTableBetLabel: '2nd col.',
    rouletteThirdColumnTableBetLabel: '3rd col.',
    infoBarPlaceChip: 'Place chip on desired bet to start.',
    rouletteInfoBetsSelected: 'bets selected',
    rouletteInfoBet: 'bet',
    rouletteInfoBarTicketProcess: 'Ticket is processing',
    payTableLabel: 'PAYTABLE',
    payTableDialogLabel: 'PAY TABLE',
    payTableText: 'Play selected bets in advance for selected numbers of rounds',
    futureRoundsLabel: 'FUTURE ROUNDS',
    futureRoundsText: 'Play selected bets in advance for selected numbers of rounds',
    insideBetsTitleLabel: 'Inside bets',
    insideBetStraightLabel: 'Stright Up',
    insideBetSplitLabel: 'Split',
    insideBetCornerLabel: 'Corner',
    insideBetStreetLabel: 'Street',
    insideBetSixlineLabel: 'Double street',
    insideBetTrioLabel: 'Trio',
    insideBetBasketLabel: 'Basket',
    outsideBetsTitleLabel: 'Outside bets',
    outsideBetDozenLabel: 'Dozen',
    outsideBetColumnLabel: 'Column',
    outsideBetHighLowLabel: 'High/Low',
    outsideBetEvenOddLabel: 'Even/Odd',
    outsideBetRedBlackLabel: 'Red/Black',
    wheelZeroGameLabel: 'ZERO GAME',
    wheelNeighboursOfZeroLabel: 'NEIGHBOURS OF ZERO',
    wheelOrphansLabel: 'ORPHANS',
    wheelThirdOfTheWheelLabel: 'THIRD OF THE WHEEL',
    resultLastRoundsLabel: 'Last 10 rounds',
    ticketCheckStatusLabel: 'Status',
    ticketCheckPayoutLabel: 'Payout',
    ticketCheckTicketLabel: 'Ticket ID',
    ticketCheckStakeLabel: 'Stake',
    ticketCheckTaxesLabel: 'Taxes',
    ticketCheckBetLabel: 'Bet',
    ticketCheckTypeLabel: 'Type',
    ticketCheckEventLabel: 'Event',
    ticketCheckWiningsLabel: 'Winnings',
    ticketSystemTicketLabel: 'SYSTEM TICKET',
    totalPaymentLabel: 'Total payment',
    totalPayoutLabel: 'Total payout',
    oddLabel: 'Odd',
    paymentLabel: 'Payment',
    payinLimits: 'Payin limits',
    maxPerBet: 'Max per bet',
    minPerBet: 'Min per bet',
    paymentTaxLabel: 'Payment tax',
    payoutTaxLabel: 'Payout tax',
    ticketTimeLabel: 'Time',
    ticketCodeLabel: 'Ticket code',
    ticketStatusLabel: 'Status',
    ticketWinLabel: 'Win',
    portraitModeSwitchMessage: 'Please switch back to potrait mode to play the game',
    ticketHistoryHeaderLabel: 'Last tickets',
    rouletteProductTitle: 'Roulette',
    possiblePayoutLabel: 'Possible payout',
    rouletteInfoBarConfirmedTicket: 'Ticket confirmed: ${id}',
    signInLabel: 'Sign in',
    lastTicketHelpTooltipLabel: 'Track your tickets here',
    clearTooltipLabel: 'Here you can clear the table',
    betslipLabel: 'Betslip',
    eventLabel: 'Event',
    presetsLabel: 'Presets',
    presetsAddLabel: 'add current selection',
    ticketIssuedLabel: 'Issued',
    presetsMaxNumber: 'You created max number of presets',
    presetNameLabel: 'Preset name',
    resultLabel: 'result',
    generateCodeLabel: 'Generate code',
    ticketCodeReadyLabel: 'Ticket is ready',
    ticketCodeOperatorLabel: 'Show this code to operator',
    okLabel: 'OK',
    balanceLabel: 'Balance',
    ticketStatusClosed: 'Closed',
    ticketStatusOpen: 'Open',
    ticketStatusLost: 'Lost',
    ticketStatusWon: 'Won',
    ticketStatusPending: 'Pending',
    ticketStatusRejected: 'Rejected',
    ticketStatusPaidOut: 'Paidout',
    ticketStatusInPlay: 'In play',
    ticketStatusWonOverdue: 'Won overdue',
    ticketStatusWonExpired: 'Won expired',
    ticketStatusExpired: 'Expired',
    ticketHistoryDateTime: 'Date and time',
    ticketHistoryPrintCopy: 'Print Copy',
    ticketHistoryToTop: 'To top',
    stakeLabel: 'Stake',
    payInLabel: 'Pay in',
    roulettePayinLabel: 'Pay in',
    roulettePayoutLabel: 'Payout',
    jackpotLabel: 'Jackpot',
    focusFirstBet: 'Focus first bet',
    perBetLabel: 'Per bet',
    focusBetInput: 'Focus bet input',
    clearBetslip: 'Clear betslip',
  },
  ui: {},
  user: {},
};
